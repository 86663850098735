<template>
  <v-card flat>
    <v-card-text>
      <v-row dense>
        <v-col class="text-center" cols="12" md="10" offset-md="1" sm="8" offset-sm="2" >
          <v-form ref="form" lazy-validation>
            <v-text-field
              label="First Name*"
              v-model="firstName"
              :rules="[() => $v.firstName.required || 'Your first name is required']"
              color="color3"
            ></v-text-field>
            <v-text-field
              label="Last Name*"
              v-model="lastName"
              :rules="[() => $v.lastName.required || 'Your last name is required']"
              color="color3"
            ></v-text-field>
            <v-text-field
              label="Email*"
              v-model="email"
              :error-messages="emailErrors"
              @blur="$v.email.$touch()"
              color="color3"
            ></v-text-field>
            <v-text-field
              label="Create a password*"
              v-model="password"
              hint="At least 6 characters"
              min="6"
              :append-icon="hidePassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
              @click:append="() => (hidePassword = !hidePassword)"
              :type="hidePassword ? 'password' : 'text'"
              :error-messages="passwordErrors"
              autocomplete="new-password"
              color="color3"
            ></v-text-field>
            <v-alert v-if="error" type="error" :value="true">
              {{error}}
            </v-alert>

            <v-row>
              <v-col class="text-center" >
                <v-btn
                  block
                  class="mb-0"
                  color="color3 color3Text--text"
                  :disabled="$v.$invalid"
                  :loading="loading"
                  @click="formSubmit">
                  Join
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

        </v-col>
      </v-row>
      <!-- BEGIN TERMS -->
      <v-row dense align="end" class="mt-2">
        <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2" class="caption text-center" >
          By joining, you agree to Volleyball Life's
          <br>
          <strong><a class="color3--text" href="/Terms" target="_blank">Terms of Service</a></strong> and <strong><a class="color3--text" href="/Privacy" target="_blank">Privacy Policy</a></strong>
        </v-col>
      </v-row>
      <!-- BEGIN OR LINE -->
      <v-row class="my-6"   >
        <v-col class="text-center" cols="12" md="10" offset-md="1" sm="8" offset-sm="2" >
          <h2 class="lined"><span>Or</span></h2>
        </v-col>
      </v-row>
      <!-- END OR LINE -->

      <!-- BEGIN SOCIAL -->
      <v-row dense justify="center">
          <facebook-login></facebook-login>
      </v-row>
      <!-- END SOCIAL -->
      <!-- BEGIN TERMS -->
      <v-row dense align="end" class="mt-2">
        <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2" class="caption text-center" >
          By continuing, you agree to Volleyball Life's
          <br>
          <strong><a class="color3--text" href="/Terms" target="_blank">Terms of Service</a></strong> and <strong><a class="color3--text" href="/Privacy" target="_blank">Privacy Policy</a></strong>
        </v-col>
      </v-row>
      <!-- END TERMS -->
      <v-row dense class="mt-4">
        <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2" class="caption text-center" >
          Already a member? <strong class="clickable color3--text" @click.stop="$emit('sign-in-click')">Sign in?</strong>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import * as actions from '@/store/ActionTypes'
import * as mutations from '@/store/MutationTypes'
import FacebookLogin from './FBLogin.vue'

export default {
  props: ['signupData', 'returnUrl', 'sub', 'error', 'active'],
  mixins: [validationMixin],
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
    password: { required, minLength: minLength(6) }
  },
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    hidePassword: true,
    hidePasswordConfirm: true,
    loading: false,
    errors: []
  }),
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('A password is required')
      !this.$v.password.minLength && errors.push('Your password must be at least 6 characters')
      !/\d/.test(this.password) && errors.push('Your password must have at least one digit (0-9).')
      return errors
    },
    valid () { return !this.$v.$invalid }
  },
  methods: {
    submit () {
      if (!this.$v.$invalid) {
        this.$store.commit(mutations.LOADING, 'Register')
        this.errors = []
        const user = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          preId: this.signupData ? this.signupData.preId : null
        }
        this.$VBL.user.register(user)
          .then((response) => {
            if (this.$auth.isAuthenticated()) {
              this.$store.dispatch(actions.LOAD_USER, 'username')
            }
          })
          .catch((error) => {
            var response = error.response.data
            if (response.errors) {
              this.errors = response.errors
            } else {
              this.errors.push(response)
            }
          })
          .finally(() => {
            this.$store.commit(mutations.LOADING_COMPLETE, 'Register')
          })
      }
    },
    formSubmit () {
      this.$store.commit(mutations.LOADING, 'Register')
      const form = document.createElement('form')
      const element1 = document.createElement('input')
      const element2 = document.createElement('input')
      const element3 = document.createElement('input')
      const element4 = document.createElement('input')
      const element5 = document.createElement('input')
      const element6 = document.createElement('input')
      const element7 = document.createElement('input')

      form.method = 'POST'
      form.action = '/Account/Register'

      element1.value = this.firstName
      element1.name = 'FirstName'
      form.appendChild(element1)

      element2.value = this.lastName
      element2.name = 'LastName'
      form.appendChild(element2)

      element3.value = this.password
      element3.name = 'Password'
      form.appendChild(element3)

      element4.value = this.email
      element4.name = 'Email'
      form.appendChild(element4)

      element5.value = this.signupData ? this.signupData.preId : null
      element5.name = 'PreId'
      form.appendChild(element5)

      element6.value = this.returnUrl
      element6.name = 'ReturnUrl'
      form.appendChild(element6)

      element7.value = this.sub
      element7.name = 'sub'
      form.appendChild(element7)

      document.body.appendChild(form)

      form.submit()
    }
  },
  watch: {
    active: function (v) {
      if (v) {
        if (this.signupData) {
          if (this.signupData.fname) this.firstName = this.signupData.fname
          if (this.signupData.lname) this.lastName = this.signupData.lname
          if (this.signupData.email) this.email = this.signupData.email
        }
      }
    }
  },
  mounted () {
    if (this.signupData) {
      if (this.signupData.fname) this.firstName = this.signupData.fname
      if (this.signupData.lname) this.lastName = this.signupData.lname
      if (this.signupData.email) this.email = this.signupData.email
    }
  },
  components: {
    FacebookLogin
  }
}
</script>

<style>
a.color3--text {
  text-decoration: none;
}
</style>
