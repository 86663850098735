<template>
  <v-card flat>
    <v-window v-model="window" touchless>

      <!-- Enter phone or email -->
      <v-window-item :value="1">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <p>
                Enter your {{mode === 'player' ? 'player number' : 'username (usually the email address you signed up with)'}} and we will send a verification code to your primary email address.  This code will sign you in directly,
                and allow you to reset your password.
              </p>
              <p>
                <strong>The verification code will only be valid for 15 minutes</strong>, so make sure you have access to your email.
              </p>
              <v-text-field
                color="color3"
                :label="mode === 'player' ? 'Player Number' : 'Username'"
                v-model="confirmation"
                :hint="mode === 'player' ? 'Enter you player number' : `Enter the username you sign in with`"
                persistent-hint
                :rules="confirmationDirty ? [
                  () => !!confirmation || `Your ${mode === 'player' ? 'player number' : 'username'} is required`,
                ] : []"
                @blur="confirmationDirty = true"
              ></v-text-field>
              <div class="caption text-right color3--text clickable" @click.stop="skip = true; window++">I already have a code</div>
              <v-alert type="error" :value="true" v-if="codeError">
                That does not match what we have on file.
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <!-- Enter verficiation code -->
      <v-window-item :value="2">
        <v-container fluid>
          <v-row dense>
            <v-col class="text-center" cols="12" >
              <div class="title">Enter the verificiation code</div>
              <div v-if="!skip">We just sent an email with a fresh verification code</div>
              <div class="caption">Note: only the last code issued will be valid and codes are only good for 15 minutes</div>
              <v-text-field
                color="color3"
                label="Username"
                v-model="confirmation"
                :hint="`Enter the username you sign in with`"
                persistent-hint
                :rules="confirmationDirty ? [
                  () => !!confirmation || 'Your username is required',
                ] : []"
                @blur="confirmationDirty = true"
              ></v-text-field>
              <v-text-field
                label="Verification Code"
                v-model="vCode"
                color="color3"
              ></v-text-field>
              <v-alert type="error" :value="true" v-if="codeError">
                That code is incorrect.
              </v-alert>
              <div class="text-center">
                <v-btn
                  color="color3 color3Text--text"
                  :disabled="!(vCode && vCode.length === 6 && confirmation)"
                  @click.stop="formSubmit"
                  :loading="loading"
                >Go</v-btn>
              </div>
              <div class="caption">Didn't receive a code? <span class="clickable color3--text" @click.stop="window--">Try again.</span></div>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>

      <!-- update password -->
      <v-window-item :value="3">
        <v-container fluid>
          <v-row dense>
            <v-col class="text-center" cols="12" >
              <div class="title">Update your password</div>
              <!-- Password -->
              <v-text-field
                :label="'Password*'"
                type="Password"
                v-model="password"
                color="color3"
                @blur="passwordDirty = true"
                :rules="passwordDirty ? [() => !!password || 'A password is required'] : []"
                autocomplete="new-password"
              ></v-text-field>
              <!-- Repeat Password -->
              <v-text-field
                :label="'Re-enter Password*'"
                type="Password"
                v-model="rPassword"
                color="color3"
                @blur="rPasswordDirty = true"
                :rules="rPasswordDirty ? [() => password === rPassword || 'Your passwords do not match'] : []"
              ></v-text-field>
              <div class="text-center">
                <v-btn
                  color="color3 color3Text--text"
                  :disabled="!!password && password !== rPassword"
                  @click.stop="savePassword"
                  :loading="loading"
                >Update</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="color3" text right class="xsmall" @click.stop="window = 1" v-if="window === 0">Forgot password</v-btn>
      <v-fab-transition>
        <v-btn color="color3Text color3--text" fab small @click.stop="backClick" v-if="window > 0">
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          color="success white--text"
          v-if="window === 1"
          :disabled="!valid"
          :loading="loading"
          @click.stop="sendCode"
        >Send Code</v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['username', 'active', 'returnUrl', 'mode'],
  data () {
    return {
      loading: false,
      window: 1,
      radio: 'email',
      confirmation: null,
      confirmationDirty: false,
      codeError: false,
      vCode: null,
      skip: false,
      verifiedPlayer: null,
      password: null,
      passwordDirty: false,
      rPassword: null,
      rPasswordDirty: false
    }
  },
  computed: {
    valid () {
      return this.confirmation
    }
  },
  methods: {
    backClick () {
      if (this.window > 1) {
        this.window--
      } else {
        this.$emit('back-click')
      }
    },
    sendCode () {
      if (!this.valid) return
      this.loading = true
      const dto = {
        username: this.confirmation
      }
      this.$VBL.user.sendCode(dto)
        .then(r => {
          this.skip = false
          this.window = 2
        })
        .catch(e => {
          this.codeError = true
          console.log(e.response.data)
        })
        .finally(() => { this.loading = false })
    },
    verifyCode () {
      if (!this.vCode || this.vCode.length !== 6) return
      this.loading = true
      const dto = {
        memberId: this.selectedProfile.id,
        code: this.vCode
      }
      this.$VBL.organization.members.code.verify(this.username, dto)
        .then(r => {
          this.verifiedPlayer = r.data
          this.window = 3
        })
        .catch(e => {
          this.codeError = true
          console.log(e.response.data)
        })
        .finally(() => { this.loading = false })
    },
    formSubmit () {
      this.$store.commit(mutations.LOADING, 'Login')
      const form = document.createElement('form')
      const element1 = document.createElement('input')
      const element2 = document.createElement('input')
      const element3 = document.createElement('input')

      form.method = 'POST'
      form.action = '/Account/Code'

      element1.value = this.confirmation
      element1.name = 'Username'
      form.appendChild(element1)

      element2.value = this.vCode
      element2.name = 'Password'
      form.appendChild(element2)

      element3.value = this.returnUrl
      element3.name = 'ReturnUrl'
      form.appendChild(element3)

      document.body.appendChild(form)

      form.submit()
    }
  }
}
</script>

<style>

</style>
