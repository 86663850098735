<template>
  <v-container>
    <v-form lazy-validation>
      <v-row dense justify="center">
        <v-col cols="12">
          <v-text-field
            :label="mode === 'email' ? 'Email' : 'Player Number'"
            v-model="username"
            :rules="[
              () => $v.username.required || (mode === 'email' ? 'An email is required' : 'A Player')
            ]"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Password"
            v-model="password"
            :append-icon="hidePassword ? 'far fa-eye' : 'far fa-eye-slash'"
            @click:append="() => (hidePassword = !hidePassword)"
            :type="hidePassword ? 'password' : 'text'"
            :rules="[() => $v.password.required || 'Password is required']"
            color="color3"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="error--text text-center" v-if="unauthorized">
          You have entered an invalid username or password.
        </v-col>
        <v-btn block
          style="max-width: 295px"
          :disabled="$v.$invalid"
          color="color3 color3Text--text"
          @click="formSubmit"
        >Sign In
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import * as actions from '../../store/ActionTypes'
import * as mutations from '../../store/MutationTypes'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: ['returnUrl', 'mode', 'sub', 'playerOnly'],
  data () {
    return {
      username: '',
      password: '',
      hidePassword: true,
      loggingIn: false,
      unauthorized: false
    }
  },
  validations () {
    return {
      username: { required },
      password: { required }
    }
  },
  methods: {
    emailLogin () {
      if (this.$v.$invalid) return
      this.$store.commit(mutations.LOADING, 'EmailLogin')
      this.unauthorized = false
      // do the post
      const user = {
        username: this.username,
        password: this.password
      }
      this.$auth.login(user)
        .then(() => {
          this.$store.dispatch(actions.ONLOGIN, 'username')
        })
        .catch((error) => {
          if (error.response.status && error.response.status === 401) {
            this.unauthorized = true
          }
        })
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, 'EmailLogin')
        })
    },
    formSubmit () {
      this.$store.commit(mutations.LOADING, 'EmailLogin')
      const form = document.createElement('form')
      const element1 = document.createElement('input')
      const element2 = document.createElement('input')
      const element3 = document.createElement('input')
      const element4 = document.createElement('input')
      const element5 = document.createElement('input')

      form.method = 'POST'
      form.action = '/Account/Login?button=login'

      element1.value = this.username
      element1.name = 'Username'
      form.appendChild(element1)

      element2.value = this.password
      element2.name = 'Password'
      form.appendChild(element2)

      element3.value = this.returnUrl
      element3.name = 'ReturnUrl'
      form.appendChild(element3)

      element4.value = this.sub
      element4.name = 'sub'
      form.appendChild(element4)

      element5.value = this.playerOnly
      element5.name = 'playerOnly'
      form.appendChild(element5)

      document.body.appendChild(form)

      form.submit()
    }
  },
  components: {
  }
}
</script>
<style>
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
</style>
