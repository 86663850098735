<template>
  <v-btn block
    style="max-width: 295px; text-transform: none;"
    color="#4267B2"
    class="white--text"
    @click.stop="goto"
  >
    <v-icon class="mr-4" color="white">fab fa-facebook</v-icon>
    Continue with Facebook
  </v-btn>
</template>

<script>
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['returnUrl'],
  methods: {
    goto () {
      this.$store.commit(mutations.LOADING, 'FBLogin')
      window.location.href = `External/Challenge?provider=Facebook&returnUrl=${encodeURIComponent(this.returnUrl)}`
    }
  }
}
</script>

<style>

</style>
