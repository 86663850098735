<template>
  <v-card flat>
    <v-card-text v-if="error" class="pb-0">
      <v-row dense>
        <v-col cols="12">
          <v-alert type="error" :value="true">
            {{error}}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-window v-model="window" touchless>
      <v-window-item :value="0" >
        <v-card-text>
          <!-- BEGIN EMAIL -->
          <v-row dense>
            <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2">
              <email-login
                :returnUrl="returnUrl"
                :sub="sub"
                :playerOnly="playerOnly"
                mode="email"
              ></email-login>
            </v-col>
          </v-row>
          <!-- END EMAIL -->

          <!-- BEGIN OR LINE -->
          <v-row class="my-6"   >
            <v-col class="text-center" cols="12" md="10" offset-md="1" sm="8" offset-sm="2" >
              <h2 class="lined"><span>Or</span></h2>
            </v-col>
          </v-row>
          <!-- END OR LINE -->

          <v-row dense justify="center">
            <v-btn
              color="color3Text color3--text"
              class="mb-2"
              style="max-width: 295px;"
              block
              @click.stop="window = 1"
            >Sign In as a Player</v-btn>
          </v-row>
          <!-- BEGIN SOCIAL -->
          <v-row dense justify="center">
            <facebook-login :returnUrl="returnUrl"></facebook-login>
          </v-row>
          <!-- END SOCIAL -->
        </v-card-text>

      </v-window-item>
      <v-window-item :value="1">
        <v-card-text>
          <!-- BEGIN EMAIL -->
          <v-row dense>
            <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2">
              <email-login
                :returnUrl="returnUrl"
                :sub="sub"
                :playerOnly="playerOnly"
                mode="player"
              ></email-login>
            </v-col>
          </v-row>
          <!-- END EMAIL -->

          <template v-if="!playerOnly">
            <!-- BEGIN OR LINE -->
            <v-row class="my-6"   >
              <v-col class="text-center" cols="12" md="10" offset-md="1" sm="8" offset-sm="2" >
                <h2 class="lined"><span>Or</span></h2>
              </v-col>
            </v-row>
            <!-- END OR LINE -->

            <v-row dense justify="center">
              <v-btn
                color="color3Text color3--text"
                style="max-width: 295px;"
                block
                @click.stop="window = 0"
              >Sign In with email</v-btn>
            </v-row>
            <!-- BEGIN SOCIAL -->
            <v-row dense justify="center">
              <facebook-login :returnUrl="returnUrl"></facebook-login>
            </v-row>
            <!-- END SOCIAL -->
          </template>
        </v-card-text>

      </v-window-item>
    </v-window>
    <v-card-text>
      <!-- BEGIN FORGOT PASSWORD AND JOIN -->
      <v-row dense>
        <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2" class="caption clickable color3--text text-center" >
          <strong @click.stop="$emit('forgot-click')">Forget your password?</strong>
        </v-col>
      </v-row>
      <template v-if="!playerOnly">
        <v-row class="ma-1"   >
          <v-col cols="4" offset="4" class="caption text-center" >
            <hr>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="10" offset-md="1" sm="8" offset-sm="2" class="caption text-center" >
            Not on Volleyball Life yet? <strong class="clickable color3--text" @click.stop="$emit('sign-up-click')">Sign Up?</strong>
          </v-col>
        </v-row>
      </template>
      <!-- END FORGOT PASSWORD AND JOIN -->
    </v-card-text>
  </v-card>
</template>

<script>
import EmailLogin from './EmailLogin.vue'
import FacebookLogin from './FBLogin.vue'

export default {
  props: ['signupData', 'returnUrl', 'playerOnly', 'sub', 'error'],
  data () {
    return {
      window: 0
    }
  },
  computed: {
  },
  components: {
    FacebookLogin,
    'email-login': EmailLogin
  },
  watch: {
    window: function (v) {
      var e = 'email'
      if (v && v === 1) e = 'player'
      this.$emit('mode-change', e)
    }
  },
  mounted () {
    if (this.playerOnly) {
      this.window = 1
    }
  }
}
</script>

<style>
  strong {
    cursor: pointer;
  }
  h2.lined {
    border-bottom: 1px solid grey;
    line-height: 0.1em;
  }
  h2.lined span {
    padding: 0 10px;
    background: #fff
  }
</style>
