<template>
<v-container>
  <v-row dense justify="center">
    <v-card width="500px">
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>
          {{title}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class='pa-0'>
        <v-window v-model="window" touchless>
          <v-window-item :value="0">
            <sign-in
              ref="signIn"
              :signupData="signupData"
              @signedIn="this.signInDialog = false"
              @sign-up-click="window = 1"
              @forgot-click="window = 2"
              @mode-change="(v) => { mode = v }"
              :returnUrl="returnUrl"
              :sub="sub"
              :error="!reg && error"
              :playerOnly="playerOnly"
            ></sign-in>
          </v-window-item>
          <v-window-item :value="1">
            <sign-up
              :signupData="signupData"
              :active="window === 1"
              @sign-in-click="window = 0"
              :returnUrl="returnUrl"
              :sub="sub"
              :error="reg && error"
            ></sign-up>
          </v-window-item>
          <v-window-item :value="2">
            <password-reset
              :username="username"
              :active="window === 2"
              @back-click="window = 0"
              :returnUrl="returnUrl"
              :mode="mode"
            ></password-reset>
          </v-window-item>
          <v-window-item :value="3">
            <v-card>
              <v-card-text>
                If you close this now you will lose any unsaved data. If you are coming from an invite email, you may not be properly linked to the invitation unless you complete the registration.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click.stop="signInDialog = false"
                >
                  Leave
                </v-btn>
                <v-btn
                  text
                  color="color3"
                  @click.stop="window = 1">Stay</v-btn>
              </v-card-actions>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

  </v-row>

</v-container>
</template>

<script>
import SignIn from '@/components/Account/SignIn.vue'
import SignUp from '@/components/Account/Register.vue'
import PasswordReset from '@/components/Account/PasswordReset.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      signupData: null,
      window: 0,
      username: null,
      mode: 'email'
    }
  },
  computed: {
    ...mapGetters(['user']),
    title () {
      switch (this.window) {
        case 0:
          return `${this.mode && this.mode === 'player' ? 'Player ' : ''}Sign In`
        case 1:
          return 'Sign Up'
        case 2:
          return 'Forgot Password?'
        case 3:
          return 'Are you sure?'
      }
      return null
    },
    returnUrl () {
      return this.getHidden('returnUrl') || this.qReturnUrl
    },
    qReturnUrl () {
      return this.$route.query.returnUrl
    },
    sub () {
      return this.getHidden('sub')
    },
    error () {
      return this.getHidden('error')
    },
    reg () {
      return this.getHidden('reg')
    },
    regData () {
      var d = this.getHidden('regData')
      if (!d) return false
      var a = d.split('&')
      return {
        fname: a.find(f => f.includes('fname')).split('=')[1],
        lname: a.find(f => f.includes('lname')).split('=')[1],
        email: a.find(f => f.includes('email')).split('=')[1],
        preId: a.find(f => f.includes('preId')).split('=')[1]
      }
    },
    playerOnly () {
      return this.getHidden('playerOnly')
    }
  },
  methods: {
    getHidden (id) {
      const el = document.getElementById(id)
      return el && el.value && el.value !== `{${id}}` ? el.value : null
    },
    checkReturnUrl () {
      if (this.returnUrl) {
        if (this.returnUrl !== this.qReturnUrl) {
          const query = {
            returnUrl: this.returnUrl
          }
          Object.assign(query, this.$route.query)
          this.$router.replace({ query })
        }
      }
    },
    checkSignup () {
      if (this.user) return
      if (this.reg) this.window = 1
      if (this.regData) {
        this.signupData = {
          fname: this.regData.fname,
          lname: this.regData.lname,
          email: this.regData.email,
          preId: this.regData.preId
        }
        this.window = 1
        // this.$router.replace(this.$route.path)
      }
    }
  },
  components: {
    SignIn,
    SignUp,
    PasswordReset
  },
  mounted () {
    this.checkReturnUrl()
    this.checkSignup()
  }
}
</script>

<style>

</style>
